import React from 'react'

const AboutPg = () => {
  return (
    <div className='n bg-red-500'>
        <h1 className='text-white bg-blue-500 text-2xl'>About us</h1>
        <h4>check possible content</h4>
    </div>
  )
}

export default AboutPg